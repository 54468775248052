<template>
  <div class="wrap">
    <Title
      pageTitle="CUSTOMIZED T-SHIRTS"
      pageDiscrption="カスタマイズTシャツ"
      color="var(--color-custom-light)"
    />
    <div class="container">
      <div class="discription">
        <p class="price">
          T-SHIRTS: 6,500円（税込）〜<br />
          LONG T-SHIRTS: 8,500円（税込）〜
          <!-- LONG T-SHIRTS: <span style="text-decoration: line-through; font-size: 1.3rem; padding-right: 1rem;">8,500円</span>6,500円（税込）〜 -->
        </p>
        <h2 class="heading-3">
          サイズ・生地・ネックなどのこだわりを<br
            class="spOnly"
          />お好みにカスタマイズ
        </h2>
        <p>
          10,000通り以上の組み合わせからお好みの仕様にカスタマイズできるTシャツです。<br
            class="pcOnly"
          />市販のTシャツでは自分の欲しいものがなかな見つからないというような、<br />Tシャツにこだわりを持った方にもご満足いただけると思います。
        </p>
      </div>

      <form method="post" action="https://cart.ec-sites.jp/sp_step2/pc/pk00/">
        <div class="form-content st-body">
          <h3 class="form-content__title heading-3">ボディタイプ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_type"
                id="tshirt"
                value="666909"
                checked=""
              />
              <label class="tshirt" for="tshirt"
                ><span class="name">T-SHIRTS</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_type"
                id="long"
                value="666910"
              />
              <label class="long-tshirt" for="long"
                ><span class="name">LONG T-SHIRTS（+2,000円）</span></label
              >
              <!-- <label class="long-tshirt" for="long"
                ><span class="name">LONG T-SHIRTS（0円）</span></label
              > -->
            </div>
          </div>
        </div>

        <div class="next line1"></div>

        <div class="form-content st-size">
          <h3 class="form-content__title heading-3">サイズ</h3>
          <SizeChart />
          <div class="form-content__size">
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_size"
                id="sizeS"
                value="0"
                checked=""
              />
              <label for="sizeS" class="check2">S</label>
            </div>
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_size"
                id="sizeM"
                value="1"
              />
              <label for="sizeM">M</label>
            </div>
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_size"
                id="sizeL"
                value="2"
              />
              <label for="sizeL">L</label>
            </div>
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_size"
                id="sizeXL"
                value="3"
              />
              <label for="sizeXL">XL</label>
            </div>
          </div>
          <div class="form-content__size">
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_style"
                id="style-fit"
                value="0"
              />
              <label for="style-fit">FIT</label>
            </div>
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_style"
                id="style-normal"
                value="1"
                checked=""
              />
              <label for="style-normal">NORMAL</label>
            </div>
            <div class="form-content__size--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_style"
                id="style-loose"
                value="2"
              />
              <label for="style-loose">LOOSE</label>
            </div>
          </div>
        </div>

        <div class="next line2"></div>

        <div class="form-content st-color">
          <h3 class="form-content__title heading-3">カラー</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="white"
                value="0"
                checked=""
              />
              <label class="color-white" for="white"
                ><span class="name">WHITE</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="gray"
                value="1"
              />
              <label class="color-gray" for="gray"
                ><span class="name">GRAY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="navy"
                value="3"
              />
              <label class="color-navy" for="navy"
                ><span class="name">NAVY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_color"
                id="black"
                value="2"
              />
              <label class="color-black" for="black"
                ><span class="name">BLACK</span></label
              >
            </div>
          </div>
          <div class="note">
            生地の種類によってグレーとネイビーは色味が異なります。詳細は<span
              @click="toFabric"
              >「生地・カラー」</span
            >をご覧ください
          </div>
        </div>

        <div class="next line3"></div>

        <div class="form-content st-fabric">
          <h3 class="form-content__title heading-3">生地</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="light"
                value="0"
              />
              <label class="fabric" for="light">
                <div class="fabric__title name">
                  LIGHT<span class="oz">4.4oz</span>
                </div>
                <div class="fabric__feature">
                  目が細かくハリとコシがある薄手生地です。程良い透け感と薄地ならではの軽量感で暑い時期に快適に着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="medium"
                value="1"
                checked=""
              />
              <label class="fabric" for="medium">
                <div class="fabric__title name">
                  MEDIUM<span class="oz">6.0oz</span>
                </div>
                <div class="fabric__feature">
                  コーマ糸を使用した高品質でスタンダードな厚みの生地です。クセがないので場面や着こなしを気にせずにご着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="heavy"
                value="2"
              />
              <label class="fabric" for="heavy">
                <div class="fabric__title name">
                  HEAVY<span class="oz">7.6oz</span>
                </div>
                <div class="fabric__feature">
                  重量感のある厚手生地です。間隔を詰めて生地を編んでいるので、耐久性に優れ、厚手により肌透けがしにくいのが特徴です
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="smooth"
                value="3"
              />
              <label class="fabric" for="smooth">
                <div class="fabric__title name">
                  SMOOTH<span class="oz">5.5oz</span>
                </div>
                <div class="fabric__feature">
                  ソフトな風合いと適度な光沢が特徴的な生地です。光沢加工を施すことで、高級感と品のある仕上がりを実現しています
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="stretch"
                value="4"
              />
              <label class="fabric" for="stretch">
                <div class="fabric__title name">
                  STRETCH<span class="oz">5.9oz</span>
                </div>
                <div class="fabric__feature">
                  縦横に伸縮する非常に柔軟性のある生地です。フィットサイズで作っても圧迫感や動きにくさを感じることはありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="mira-wave"
                value="5"
              />
              <label class="fabric" for="mira-wave">
                <div class="fabric__title name">
                  MIRA WAVE<span class="oz">8.8oz</span>
                </div>
                <div class="fabric__feature">
                  抗菌処理を施し、速乾・接触冷感・UVカットを兼ね備えた生地です。シワがつかず、洗濯による縮みもほとんどありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_fabric"
                id="coolmax"
                value="6"
              />
              <label class="fabric" for="coolmax">
                <div class="fabric__title name">
                  COOLMAX<span class="oz">4.6oz</span>
                </div>
                <div class="fabric__feature">
                  吸収速乾に優れた素材です。涼しく快適な着心地がありますので、スポーツはもちろんアクティブな場面におすすめです
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="next line4"></div>

        <div class="form-content st-neck">
          <h3 class="form-content__title heading-3">ネック</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="O"
                value="0"
                checked=""
              />
              <label class="neck-o neck" for="O"
                ><span class="name">O-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="O-Deep"
                value="3"
              />
              <label class="neck-o-deep neck" for="O-Deep"
                ><span class="name">O-DEEP</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="V"
                value="1"
              />
              <label class="neck-v neck" for="V"
                ><span class="name">V-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_neck"
                id="V-Deep"
                value="4"
              />
              <label class="neck-v-deep neck" for="V-Deep"
                ><span class="name">V-DEEP</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line5"></div>

        <div class="form-content st-necklib">
          <h3 class="form-content__title heading-3">ネックリブタイプ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_lib"
                id="casual"
                value="0"
                checked=""
              />
              <label class="casual lib" for="casual"
                ><span class="name">CASUAL</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_simple_attr_sel[]_lib"
                id="formal"
                value="1"
              />
              <label class="formal lib" for="formal"
                ><span class="name">FORMAL</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line6"></div>

        <div class="form-content st-pocket">
          <h3 class="form-content__title heading-3">ポケット</h3>
          <div class="form-content__select2">
            <div class="form-content__select2--item">
              <label class="pocket"></label>
            </div>
            <div class="form-content__select2--item">
              <input
                type="radio"
                name="es_option[]_pocket"
                id="noPocket"
                value="666905"
                checked=""
              />
              <label for="noPocket"><span class="name">無し</span></label>
              <input
                type="radio"
                name="es_option[]_pocket"
                id="Pocket"
                value="666906"
              />
              <label for="Pocket"
                ><span class="name">有り（+1,000円）</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line7"></div>

        <div class="form-content st-sleeve">
          <h3 class="form-content__title heading-3">袖口リブ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_listlib"
                id="noLib"
                value="666907"
                checked=""
              />
              <label class="noLib sleeve-lib" for="noLib"
                ><span class="name">リブなし</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="es_option[]_listlib"
                id="addLib"
                value="666908"
              />
              <label class="addLib sleeve-lib" for="addLib"
                ><span class="name">リブ付き（+1,000円）</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line8"></div>

        <div class="form-content st-resize">
          <h3 class="form-content__title heading-3">
            お直しサービス（着丈のみ）
          </h3>
          <div class="form-content__select2">
            <div class="form-content__select2--item">
              <label class="fix"></label>
            </div>
            <div class="form-content__select2--item">
              <div class="fix-form">
                <p>
                  着丈を無料で調整させていただきます。
                  ご希望の方は下記に着丈の寸法をご記入ください。
                </p>
                <div class="textarea">
                  <input type="number" name="es_simple_attr_text[]" />
                  <div>cm</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="submit-wrap">
          <div class="quanity">
            <span>枚数</span>
            <input type="number" name="es_item_qty" value="1" id="quanity" />
          </div>
          <div class="submit">
            <input type="hidden" name="es_charset" value="sjis" />
            <input type="hidden" name="es_item_id" value="48" />
            <input type="hidden" name="es_shop_id" value="4777" />
            <input type="hidden" name="es_stock_attr_flag" value="0" />
            <button
              type="submit"
              name="es_submit"
              value="カートに入れる"
              class="submit_btn"
            >
              カートに入れる
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import Title from '../components/Title.vue'
import SizeChart from '../components/SizeChart.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted } from '@vue/runtime-core'

export default {
  components: { Title, SizeChart },
  setup() {
    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger)

      formContent('.st-body')
      formContent('.st-size')
      formContent('.st-color')
      formContent('.st-fabric')
      formContent('.st-neck')
      formContent('.st-necklib')
      formContent('.st-pocket')
      formContent('.st-sleeve')
      formContent('.st-resize')
      nextLine('.line1')
      nextLine('.line2')
      nextLine('.line3')
      nextLine('.line4')
      nextLine('.line5')
      nextLine('.line6')
      nextLine('.line7')
      nextLine('.line8')
    })

    const formContent = (item) => {
      gsap.fromTo(
        item,
        { y: 150, autoAlpha: 0 },
        {
          y: 0,
          autoAlpha: 1,
          duration: 0.3,
          delay: 0.3,
          scrollTrigger: { trigger: item, start: '20% bottom' },
        }
      )
    }
    const nextLine = (item) => {
      gsap.to(item, {
        height: 70,
        duration: 0.3,
        scrollTrigger: { trigger: item, start: 'bottom 70%' },
      })
    }

    const isActive = ref(true)
    const byBodySize = () => {
      isActive.value = true
    }
    const byTshirtSize = () => {
      isActive.value = false
    }
    const router = useRouter()
    const toFabric = () => {
      router.push({ name: 'Fabrics' })
    }
    return { isActive, byBodySize, byTshirtSize, toFabric }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url('../assets/img/customize-bg.jpg');
  background-size: 120%;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-size: 300%;
  }
}
.discription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;

  & p {
    text-align: center;
    line-height: 2;
  }

  & .price {
    font-family: 'objektiv-mk1', sans-serif;
  }

  & .heading-3 {
    margin: 5rem 0 3.5rem;
    font-size: 18px;
    line-height: 1.8;

    @media screen and (max-width: 425px) {
      text-align: center;
    }

    & + p {
      @media screen and (max-width: 425px) {
        text-align: left;
      }
    }
  }
}

.form-content {
  width: 100%;
  background-color: #000;
  padding: 4rem;
  text-align: center;
  border-radius: 10px;

  @media screen and (max-width: 425px) {
    padding: 2rem;
  }

  &__title {
    margin-bottom: 4rem;

    @media screen and (max-width: 425px) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }
  }

  &__select {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    &--item {
      display: flex;
      flex-direction: column;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 3px solid #fff;
        opacity: 1;
      }

      & label {
        width: 100%;
        min-height: 100%;
        height: 350px;
        border: 3px solid #000;
        border-radius: 10px;
        background-size: cover;
        background-color: #000;
        background-position: center;
        transition: border 0.3s ease-out;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 2rem;
        font-family: 'objektiv-mk1', sans-serif;
        opacity: 0.8;

        & .name {
          background-color: var(--color-heavy);
          display: inline-block;
          padding: 5px 15px;
          font-size: 11px;
          font-family: 'objektiv-mk1', sans-serif;
          border-radius: 100px;
        }

        &.tshirt {
          background-image: url('../assets/img/bodyType-Tshirt.jpg');
        }
        &.long-tshirt {
          background-image: url('../assets/img/bodyType-LongTshirt.jpg');
        }
        &.color-white {
          background-image: url('../assets/img/white.jpg');
        }
        &.color-gray {
          background-image: url('../assets/img/gray.jpg');
        }
        &.color-navy {
          background-image: url('../assets/img/navy.jpg');
        }
        &.color-black {
          background-image: url('../assets/img/black.jpg');
        }
        &.neck-o {
          background-image: url('../assets/img/neck-O.jpg');
        }
        &.neck-v {
          background-image: url('../assets/img/neck-V.jpg');
        }
        &.neck-o-deep {
          background-image: url('../assets/img/neck-O-Deep.jpg');
        }
        &.neck-v-deep {
          background-image: url('../assets/img/neck-V-Deep.jpg');
        }
        &.casual {
          background-image: url('../assets/img/lib-casual.jpg');
        }
        &.formal {
          background-image: url('../assets/img/lib-formal.jpg');
        }
        &.slim {
          background-image: url('../assets/img/lib-slim.jpg');
        }
        &.medium {
          background-image: url('../assets/img/lib-medium.jpg');
        }
        &.wide {
          background-image: url('../assets/img/lib-wide.jpg');
        }
        &.noLib {
          background-image: url('../assets/img/lib-fold.jpg');
        }
        &.addLib {
          background-image: url('../assets/img/lib-attach.jpg');
        }
      }

      & .fabric {
        width: 100%;
        height: auto;
        padding: 3rem 2rem;
        justify-content: center;
        align-items: center;
        background-color: var(--color-dark);

        &__title {
          margin-bottom: 1rem;
          font-family: 'objektiv-mk1', sans-serif;
          font-weight: 700;
          display: flex;
          align-items: center;
          font-size: 16px;
          letter-spacing: 0.2rem;

          & .oz {
            font-size: 11px;
            margin-left: 5px;
          }
        }

        &__feature {
          font-size: 12px;
          line-height: 1.6;
          text-align: left;
        }
      }

      & .neck {
        height: 250px;
      }

      & .lib {
        @media screen and (max-width: 425px) {
          height: 250px;
        }
      }

      & .lib-wide {
        height: 250px;
      }

      & .sleeve-lib {
        height: 250px;
      }
    }
  }
  &__size {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
    justify-content: center;
    grid-gap: 1rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    &--item {
      display: flex;
      flex-direction: column;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 1px solid #fff;
        background-color: #1a1a1a;
        opacity: 1;
      }

      & label {
        border: 1px solid var(--color-dark);
        border-radius: 100px;
        background-color: #000;
        transition: border 0.3s ease-out;
        cursor: pointer;
        padding: 1rem;
        font-family: 'objektiv-mk1', sans-serif;
      }
    }
  }

  &__select2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 1px solid #fff;
        background-color: #1a1a1a;
        opacity: 1;
      }

      & label {
        border: 1px solid var(--color-dark);
        border-radius: 100px;
        background-color: #000;
        transition: border 0.3s ease-out;
        cursor: pointer;
        padding: 1rem;
        font-family: 'objektiv-mk1', sans-serif;
        margin-bottom: 2rem;
        width: 80%;

        @media screen and (max-width: 625px) {
          margin-bottom: 0;
          margin-top: 1.5rem;
        }

        &.pocket {
          background-image: url('../assets/img/pocket.jpg');
          background-size: cover;
          background-position: center;
          height: 250px;
          border-radius: 10px;
          margin-bottom: 0;
          border: none;
          width: 100%;

          @media screen and (max-width: 625px) {
            margin-top: 0;
          }
        }

        &.fix {
          background-image: url('../assets/img/fix-custom.jpg');
          background-size: cover;
          background-position: center;
          height: 250px;
          border-radius: 10px;
          margin-bottom: 0;
          border: none;
          width: 100%;
        }
      }
      & .fix-form {
        width: 80%;

        & .textarea {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid var(--color-dark);

          & input {
            display: block;
            margin-top: 1rem;
            margin-right: 1rem;
            width: 100%;
            background-color: #1a1a1a;
            border: none;
            padding: 2rem;
            outline: none;
            color: var(--color-light);
            font-size: 18px;
            font-family: 'objektiv-mk1', sans-serif;
          }
        }
      }
    }
  }
}

.note {
  margin-top: 2rem;
  font-size: 12px;

  & span {
    font-size: 12px;
    cursor: pointer;
  }
}

.next {
  width: 1px;
  height: 0;
  background-color: var(--color-dark);
  margin: 10px auto;
}

.info-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }

  & input {
    display: none;
  }

  & input:checked + label {
    border: 1px solid var(--color-light);
    background-color: #1a1a1a;
  }

  &__item {
    width: 300px;
    margin: 0 10px;
    color: var(--color-light);
    padding: 15px 20px;
    border: 1px solid #555;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    transition: border 0.3s ease-out;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__item:hover {
    border: 1px solid #aaa;
  }
}

.submit-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6rem 0 2rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  & .quanity {
    margin: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      margin-bottom: 3rem;
    }

    & input {
      background-color: #222;
      border: 1px solid var(--color-dark);
      color: var(--color-light);
      padding: 1.8rem 2rem;
      margin: 0 3rem 0 1rem;
      width: 120px;
      text-align: center;
      font-size: 18px;
      outline: none;
    }
  }

  & .submit {
    & button {
      color: var(--color-light);
      width: 300px;
      background-color: var(--color-custom-light);
      padding: 15px 20px;
      border: none;
      border-radius: 100px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 300;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        background-color: var(--color-custom-dark);
      }
    }
  }
}
</style>
