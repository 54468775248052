<template>
  <table>
    <tr>
      <th></th>
      <th>スタイル</th>
      <th>着丈</th>
      <th>着幅</th>
      <th>裾幅</th>
      <th>肩幅</th>
      <th>袖丈</th>
      <th>袖丈*</th>
    </tr>

    <tr>
      <td rowspan="3">S</td>
      <td>FIT</td>
      <td rowspan="3">65</td>
      <td>45</td>
      <td>41</td>
      <td>36</td>
      <td rowspan="3">19</td>
      <td rowspan="3">56</td>
    </tr>
    <tr>
      <td>NORMAL</td>
      <td>48</td>
      <td>45</td>
      <td>38</td>
    </tr>
    <tr>
      <td>LOOSE</td>
      <td>51</td>
      <td>48</td>
      <td>40</td>
    </tr>

    <tr>
      <td rowspan="3">M</td>
      <td>FIT</td>
      <td rowspan="3">69</td>
      <td>48</td>
      <td>43</td>
      <td>38</td>
      <td rowspan="3">20</td>
      <td rowspan="3">59</td>
    </tr>
    <tr>
      <td>NORMAL</td>
      <td>51</td>
      <td>47</td>
      <td>40</td>
    </tr>
    <tr>
      <td>LOOSE</td>
      <td>54</td>
      <td>51</td>
      <td>42</td>
    </tr>

    <tr>
      <td rowspan="3">L</td>
      <td>FIT</td>
      <td rowspan="3">72</td>
      <td>51</td>
      <td>46</td>
      <td>40</td>
      <td rowspan="3">21</td>
      <td rowspan="3">62</td>
    </tr>
    <tr>
      <td>NORMAL</td>
      <td>54</td>
      <td>50</td>
      <td>42</td>
    </tr>
    <tr>
      <td>LOOSE</td>
      <td>57</td>
      <td>54</td>
      <td>44</td>
    </tr>

    <tr>
      <td rowspan="3">XL</td>
      <td>FIT</td>
      <td rowspan="3">76</td>
      <td>54</td>
      <td>49</td>
      <td>42</td>
      <td rowspan="3">22</td>
      <td rowspan="3">65</td>
    </tr>
    <tr>
      <td>NORMAL</td>
      <td>57</td>
      <td>53</td>
      <td>44</td>
    </tr>
    <tr>
      <td>LOOSE</td>
      <td>60</td>
      <td>57</td>
      <td>46</td>
    </tr>
  </table>

  <div class="note">*ロングTシャツ</div>
</template>

<style lang="scss" scoped>
table {
  font-family: 'objektiv-mk1', sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0 10px;
}
table th,
table td {
  font-size: 1.3rem;
  border: 1px solid #555;
  letter-spacing: 0.01;
  padding: 5px 0;
}
table th {
  background-color: #1c1c1c;
  border-bottom: 2px solid #999;
  padding: 10px 0;
}
.note {
  font-size: 11px;
  margin-bottom: 2rem;
  text-align: right;
}
</style>
